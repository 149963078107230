/* Footer Container */
footer {
    width: 100%;
    background-color: #f8f9fa; /* Light background color */
    padding: 40px 20px;
    text-align: center;
    border-top: 1px solid #e0e0e0; /* Subtle border at the top */
  }
  
  /* Footer Title */
  .footer-title {
    font-size: 24px;
    font-weight: 600;
    color: #333333; /* Dark text for contrast */
    margin-bottom: 10px;
  }
  
  /* Footer Subtitle */
  .footer-subtitle {
    font-size: 18px;
    font-weight: 500;
    color: #555555; /* Slightly lighter text */
    margin-bottom: 20px;
  }
  
  /* Google Map Container */
  .map-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto 20px auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .map-container iframe {
    width: 100%;
    height: 450px;
    border: 0;
  }
  
  /* Call-to-Action Button */
  .cta-container {
    margin: 20px 0;
  }
  
  .cta-button {
    background-color: #007bff; /* Blue color */
    color: #ffffff; /* White text */
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  /* Office Address */
  .office-address {
    margin: 20px 0;
  }
  
  .office-address h4 {
    font-size: 20px;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .office-address p {
    font-size: 16px;
    color: #555555;
  }
  
  /* Contact Form */
  .contact-form {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form h4 {
    font-size: 20px;
    color: #333333;
    margin-bottom: 20px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .contact-form textarea {
    resize: vertical;
  }
  
  .contact-form button {
    background-color: #007bff;
    color: #ffffff;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  /* Social Media Links */
  .social-media {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .social-media a {
    text-decoration: none;
    color: #333333; /* Dark text for contrast */
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    transition: color 0.3s ease;
  }
  
  .social-media a:hover {
    color: #007bff; /* Blue color on hover */
  }
  
  .social-media i {
    font-size: 24px;
    color: #ff0000; /* Red color for icons */
  }
  
  /* Footer Links */
  .footer-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .footer-links a {
    text-decoration: none;
    color: #333333; /* Dark text for contrast */
    font-size: 16px;
    transition: color 0.3s ease;
  }
  
  .footer-links a:hover {
    color: #007bff; /* Blue color on hover */
  }
  
  /* Copyright Text */
  .copyright {
    font-size: 14px;
    color: #777777; /* Light gray text */
    margin: 0;
  }