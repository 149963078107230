/* Navbar Container */
nav {
    padding: 10px 20px;
    background-color: #ffffff; /* Light background */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  /* Logo and Brand Name */
  .navbar-brand {
    display: flex;
    align-items: center;
  }
  
  .navbar-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  
  .navbar-logo:hover {
    transform: scale(1.1);
  }
  
  .logo-text {
    color: #333333; /* Dark text for contrast */
    font-size: 25px;
    font-weight: bold;
  }
  
  /* Navbar Links */
  .navbar-nav {
    gap: 40px;
  }
  
  .nav-link {
    color: #333333 !important; /* Dark text for contrast */
    font-size: 18px;
    font-weight: 500;
    transition: color 0.3s ease, border-bottom 0.3s ease;
  }
  
  .nav-link:hover {
    color: #005CEC !important; /* Accent color on hover */
  }
  
  .nav-link.active {
    border-bottom: 2px solid #005CEC; /* Highlight active link */
  }
  
  /* Hamburger Menu */
  .navbar-toggler {
    border: none;
    outline: none;
  }
  
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(51, 51, 51, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  
  /* Responsive Design */
  @media only screen and (max-width: 992px) {
    .navbar-nav {
      background-color: rgba(255, 255, 255, 0.9); /* Light background for mobile menu */
      padding: 10px;
      border-radius: 5px;
      margin-top: 10px;
      text-align: center;
    }
  
    .nav-link {
      text-align: center;
    }
  
    .navbar-logo {
      width: 40px;
      height: 40px;
    }
  
    .logo-text {
      font-size: 20px;
    }
  }