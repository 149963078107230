/* Slider Container */
.slider-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

/* Slider */
.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

/* Slide */
.slide {
  min-width: 100%;
  height: 400px; /* Adjust height as needed */
  background-size: cover; /* Cover the entire slide */
  background-position: center; /* Center the image */
  flex-shrink: 0; /* Prevent slides from shrinking */
}

/* Navigation Buttons */
.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
  transition: background-color 0.3s ease;
}

.slider-button:hover {
  background-color: rgba(0, 0, 0, 0.8); /* Darker on hover */
}

.slider-button.prev {
  left: 10px;
}

.slider-button.next {
  right: 10px;
}

/* Dots Indicator */
.dots-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: white; /* Solid white for active dot */
}

.dot:hover {
  background-color: rgba(255, 255, 255, 0.8); /* Brighter on hover */
}