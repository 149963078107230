@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');

* {
    box-sizing: border-box;
}

body {
    margin: 0;
}

.header {
    text-align: center;
    padding: 32px;
}

.header h1 {
    font-family: 'Sacramento';
    font-size: 50px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 20px 0px;
}

.column {
    flex: 25%;
    max-width: 25%;
}

.img-hover-zoom {
    height: auto;
    overflow: hidden;
    border: 5px solid #ffff;
}

.img-hover-zoom img {
    vertical-align: middle;
    margin: 0;
    padding: 0;
    border: 0;
    transition: transform .5s ease;
}

.img-hover-zoom:hover img {
    transform: scale(1.5);
}

@media screen and (max-width: 800px) {
    .column {
        flex: 50%;
        max-width: 80%;
    }
}

@media screen and (max-width: 500px) {
    .column {
        flex: 100%;
        width: 100%;
    }
    .row{
        padding: 0px 0px 0px 0px;
        justify-content: center;
    }
}